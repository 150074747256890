var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AMeasureProcessStepContainer',{attrs:{"title":_vm.$ft(_vm.doc.title),"current":_vm.doc.state === 'wip' || _vm.doc.state === 'verify',"is-verifying":_vm.doc.state === 'verify',"attachments":_vm.doc.attachmentsCount,"comments":_vm.doc.commentsCount,"signature":_vm.doc.signature,"date":_vm.doc.dueDate,"user":_vm.doc.responsible}},[_c('div',{staticClass:"step-cause-analysis"},[(_vm.doc.state === 'new')?_c('div',{staticClass:"new"},[_c('v-textarea',{attrs:{"outlined":"","auto-grow":"","rows":"4","hide-details":"","label":_vm.$t(
            'components.controls.measure_process_steps.measure_process_step_cause_analysis.opt_comment_label'
          )},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('div',{staticClass:"d-flex mt-4"},[_c('a-user-autocomplete',{staticClass:"mr-4",attrs:{"label":_vm.$t(
              'components.controls.measure_process_steps.measure_process_step_cause_analysis.responsible_employee_label'
            ),"hide-details":"","outlined":"","dense":""}}),_c('AMenuDatePicker',{attrs:{"outlined":true,"dense":true,"label":_vm.$t(
              'components.controls.measure_process_steps.measure_process_step_direct_measure.target_date_label'
            ),"return-string":true},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1):_vm._e(),(_vm.doc.state === 'wip')?_c('div',{staticClass:"wip"},[_c('v-textarea',{attrs:{"outlined":"","auto-grow":"","rows":"4","hide-details":"","label":_vm.$t(
            'components.controls.measure_process_steps.measure_process_step_cause_analysis.opt_comment_label'
          )},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('v-select',{staticClass:"mt-4",attrs:{"items":['Methode 1', 'Methode 2', 'Methode 3'],"hide-details":"","outlined":"","label":_vm.$t(
            'components.controls.measure_process_steps.measure_process_step_cause_analysis.selected_method_label'
          ),"dense":""}})],1):_vm._e(),(_vm.doc.state === 'done')?_c('div',{staticClass:"finished"},[_vm._v(_vm._s(_vm.doc.text))]):_vm._e()]),(!['done', 'verify'].includes(_vm.doc.state))?_c('template',{slot:"actions"},[(_vm.doc.state === 'new')?_c('v-btn',{attrs:{"text":"","color":"success darken-1"},on:{"click":function($event){return _vm.startStep(_vm.doc.id)}}},[_vm._v(" Starten ")]):_vm._e(),(_vm.doc.state === 'wip')?_c('v-btn',{attrs:{"text":""}},[_vm._v("Abschließen")]):_vm._e(),(_vm.doc.state === 'new')?_c('v-btn',{attrs:{"text":""}},[_vm._v("Überspringen")]):_vm._e()],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }