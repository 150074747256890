




















































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";
import { api } from "@/store/modules/measureProcess";

import { MeasureProcessStepCauseAnalysis } from "@/store/modules/measureProcess/types";

@Component({})
export default class AMeasureProcessStepCauseAnalysis extends Vue {
  date = null;

  @Prop({
    type: MeasureProcessStepCauseAnalysis,
  })
  readonly doc!: MeasureProcessStepCauseAnalysis;

  @Action(api.actions.startStep, {
    namespace: api.namespace,
  })
  startStep!: (payload: any) => Promise<void>;
}
